import { jsx as _jsx } from "react/jsx-runtime";
import { createBrowserRouter } from 'react-router-dom';
import DashboardHomeComponent from 'components/dashboard/dashboard_home_component';
import UnderMaintenanceComponent from 'components/utils/under_maintenance_component';
import DashboardRestrictAllComponent from 'components/dashboard/dashboard_restrict_all_component';
import ErrorBoundary from 'components/utils/error_boundary_component';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import dashboardRoutes from './dashboard';
import userRoutes from './user';
import NotFoundComponent from 'components/utils/NotFoundComponent';
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
const DashboardContainerComponent = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard" */ 'components/dashboard/dashboard_container_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const RealtimeVehicleJobShareComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-isolated-realtimeVehicleJobShare" */ 'components/dashboard/route_dispatch/jobs/share/real_time_vehicle_job_share_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const RealtimeVehicleListJobShareComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-isolated-realtimeVehicleJobListShare" */ 'components/dashboard/route_dispatch/jobs/share/real_time_vehicle_job_share_container_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const JobShareDetailComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-isolated-jobShare" */ 'components/dashboard/route_dispatch/jobs/share/job_share_detail_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const LatestTimelineShareComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-isolated-latestTimeLineShare" */ 'components/dashboard/realtime/latest_timeline_share_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const EPODShareComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-isolated-epodComponent" */ 'components/dashboard/realtime/epod_share_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ShareTripTimelineComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-isolated-tripTimelineShare" */ 'components/dashboard/trips/timeline/share/share_trip_timeline_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ShareClientFeedbackFormComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-isolated-clientfeedbackShare" */ 'components/dashboard/route_dispatch/jobs/share/client_feedback/share_client_feedback_form_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const TripShareDetailComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-isolated-tripShare" */ 'components/dashboard/trips/trip_share_detail_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const RouteShareComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-isolated-routeShare" */ 'components/dashboard/route_dispatch/route/v2/add_edit_route_wrapper_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const RealtimeVehicleShareComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-isolated-realtimeVehicleShare" */ 'components/dashboard/realtime/share/realtime_vehicle_share_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AskLocationComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-isolated-askLocation" */ 'components/dashboard/route_dispatch/addressbook/ask_location_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const vtAlarmShareComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-isolated-alert-sharing" */ 'components/dashboard/alerts/alert_sharing'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const vtCallingShareComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-call-shaing" */ 'components/dashboard/telematics/twoWayCallingShare'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const vtVideoShareComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-isolated-video-sharing" */ 'components/dashboard/telematics/video_share'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ConsignmentMilestoneComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-consignments-milestone" */ 'components/dashboard/consignments/ConsignmentMilestoneComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const vtVehicleVideoShareComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vehicle-video-sharing" */ 'components/dashboard/telematics/vehicle_video_share'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const vtVehicleVideoPlaybackComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vehicle-playback-sharing" */ 'components/dashboard/telematics/vehicle_playback_share'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const CustomerLocationClusterComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-customer-location-cluster-shared" */ 'components/dashboard/internal_tools/operations/CustomerClusterView/CustomerLocationClusterComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
export const router = createBrowserRouter([
    {
        path: '/',
        element: _jsx(DashboardHomeComponent, {}),
        errorElement: _jsx(RouteErrorBoundary, {}),
    },
    {
        path: 'users',
        children: userRoutes,
    },
    {
        path: 'dashboard',
        lazy: DashboardContainerComponent,
        children: dashboardRoutes,
    },
    {
        path: 'undermaintenance',
        element: _jsx(UnderMaintenanceComponent, {}),
        errorElement: _jsx(RouteErrorBoundary, {}),
    },
    {
        path: 'restricted',
        element: _jsx(DashboardRestrictAllComponent, {}),
        errorElement: _jsx(RouteErrorBoundary, {}),
    },
    {
        path: 'vehicles/job/share/:vehicleJobShareToken/:jobId',
        lazy: RealtimeVehicleJobShareComponent,
    },
    {
        path: 'vehicles/job/share/:vehicleJobShareToken',
        lazy: RealtimeVehicleListJobShareComponent,
    },
    {
        path: 'job/share/:jobShareToken',
        lazy: JobShareDetailComponent,
    },
    {
        path: 'trip/latest/share/:vehicleId',
        lazy: LatestTimelineShareComponent,
    },
    {
        path: 'epod/:epodShareToken',
        lazy: EPODShareComponent,
    },
    {
        path: 'trips/share/timeline/:timelineShareToken',
        lazy: ShareTripTimelineComponent,
    },
    {
        path: 'live/share/feedback/:feedbackShareToken',
        lazy: ShareClientFeedbackFormComponent,
    },
    {
        path: 'trip/share/:tripShareToken',
        lazy: TripShareDetailComponent,
    },
    {
        path: 'live/share/routes/:shareToken',
        lazy: RouteShareComponent,
    },
    {
        path: 'live/share/:version/:liveShareToken',
        lazy: RealtimeVehicleShareComponent,
    },
    {
        path: 'satavtracking/live/share',
        lazy: RealtimeVehicleShareComponent,
    },
    {
        path: 'live/share/:liveShareToken',
        lazy: RealtimeVehicleShareComponent,
    },
    {
        path: 'share/alarm/:liveShareToken',
        lazy: vtAlarmShareComponent,
    },
    {
        path: 'share/calling',
        lazy: vtCallingShareComponent,
    },
    {
        path: 'share/video/:liveShareToken',
        lazy: vtVideoShareComponent,
    },
    {
        path: 'location/ask/:askLocationToken',
        lazy: AskLocationComponent,
    },
    {
        path: 'live/consignment/:consignmentId',
        lazy: ConsignmentMilestoneComponent,
    },
    {
        path: 'shared/live-stream/:liveShareToken',
        lazy: vtVehicleVideoShareComponent,
    },
    {
        path: 'shared/playback/:liveShareToken',
        lazy: vtVehicleVideoPlaybackComponent,
    },
    {
        path: 'shared/customerLocationClusterView',
        lazy: CustomerLocationClusterComponent,
    },
    {
        path: '*',
        element: (_jsx(ErrorBoundary, { children: _jsx(NotFoundComponent, {}) })),
    },
]);
