import { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LightBoxUtils from '../utils/light_box_utils';
import { round, get, forEach, set, map } from 'lodash';
import { handleError } from '../constant';
import {
    customProgressBarConfig,
    FILE_UPLOAD_STATUS,
    getShowUploadListConfig,
    getUrlToUploadFileObject,
    getValidUploadFileTypes,
    iconRender,
    setFieldValueOnFile,
    UPLOAD_LIST_TYPE,
    validateFileBeforeUpload,
} from 'utils/fileUploadutils';
import { FxDropzone, FxUploadDragIconWrapper, FxUploadHintWrapper, FxUploadTextWrapper } from '../sharedV2';
import { withTranslation } from 'react-i18next';
import Styled from 'styled-components';

const SingleFileDropzoneToGivenUrlWrapper = Styled.div`
    .ant-progress-line{
      width:98%
    }
`;

class SingleFileDropzoneToGivenUrlComponent extends Component {
    source = null;

    constructor(props) {
        super(props);
        this.state = {
            acceptedFile: null,
            isOpen: false,
            fileList: props.uploadedUrl ? [getUrlToUploadFileObject(props.uploadedUrl)] : [],
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.uploadedUrl !== this.props.uploadedUrl && !this.state.acceptedFile) {
            this.setState({
                fileList: this.props.uploadedUrl ? [getUrlToUploadFileObject(this.props.uploadedUrl)] : [],
            });
        }
    }

    onCloseRequest = () => {
        this.setState({
            isOpen: false,
        });
    };
    handleOnClick = () => {
        this.setState({
            isOpen: true,
        });
    };

    setFieldOnFile = (pairs) => {
        const file = this.state.fileList[0];
        if (file) {
            this.setState({
                fileList: [setFieldValueOnFile(file, pairs)],
            });
        }
    };

    onDrop = async (acceptedFiles) => {
        const acceptedFile = acceptedFiles[0];
        if (!acceptedFile) {
            return;
        }
        if (this.source) {
            this.source.cancel('');
        }

        const fileValidationObj = await validateFileBeforeUpload(acceptedFile);
        if (get(fileValidationObj, 'error')) {
            this.setFieldOnFile([
                ['status', FILE_UPLOAD_STATUS.ERROR],
                ['error', get(fileValidationObj, 'errorMsg')],
            ]);
            this.props.fileDeleteCallback();
            alert(get(fileValidationObj, 'errorMsg'));
            return;
        }

        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();

        const config = {
            onUploadProgress: (progressEvent) => {
                this.setFieldOnFile([['percent', round((progressEvent.loaded * 100) / progressEvent.total)]]);
            },
            cancelToken: this.source.token,
        };

        this.setState({
            acceptedFile: acceptedFile,
        });
        const data = new FormData();
        data.append('file', acceptedFile);

        // attach any extra fields to body
        forEach(this.props.extraData, (value, key) => {
            data.append(key, value);
        });
        axios
            .post(this.props.uploadToEndPoint, data, config)
            .then((res) => {
                const filePath =
                    typeof res.data === 'string' ? res.data : get(res.data, get(this.props, 'pathToUrlInData'));
                this.props.fileSuccessCallback(filePath);
                this.setFieldOnFile([
                    ['status', FILE_UPLOAD_STATUS.DONE],
                    ['url', filePath],
                ]);
            })
            .catch((err) => {
                this.props.fileErrorCallback(err.response.data.message);
                this.setFieldOnFile([
                    ['status', FILE_UPLOAD_STATUS.ERROR],
                    ['error', handleError(err.response)],
                ]);
            });
    };

    componentWillUnmount() {
        this.source = null;
    }

    beforeUpload = (file) => {
        set(file, 'status', FILE_UPLOAD_STATUS.UPLOADING);
        this.setState(
            {
                fileList: [file],
            },
            () => {
                this.onDrop(this.state.fileList);
            }
        );
        return false;
    };
    onFileRemove = () => {
        if (this.source) {
            this.source.cancel('');
        }
        this.setState(
            {
                fileList: [],
            },
            () => {
                this.props.fileDeleteCallback();
            }
        );
    };
    onPreview = (event) => {
        this.handleOnClick();
    };

    render() {
        const {
            name,
            accept,
            message = this.props.t('dropzoneTextMsg'),
            iconMessage,
            hintMessage,
            onlyView,
            listType = UPLOAD_LIST_TYPE.PICTURE,
        } = this.props;
        const { fileList } = this.state;
        const uploadedImageLinks = map(fileList, (f) => f.url);
        const uploadProps = {
            onRemove: this.onFileRemove,
            name,
            beforeUpload: this.beforeUpload,
            fileList,
            listType,
            multiple: false,
            disabled: onlyView,
            accept: getValidUploadFileTypes(accept),
            iconRender: iconRender(this.onPreview),
            isImageUrl: () => false,
            maxCount: 1,
            progress: customProgressBarConfig(),
            showUploadList: getShowUploadListConfig({}),
        };

        return (
            <SingleFileDropzoneToGivenUrlWrapper>
                {this.state.isOpen && (
                    <LightBoxUtils images={uploadedImageLinks} onCloseRequest={this.onCloseRequest} />
                )}
                <FxDropzone {...uploadProps}>
                    {!!iconMessage && <FxUploadDragIconWrapper>{iconMessage}</FxUploadDragIconWrapper>}
                    <FxUploadTextWrapper>{message}</FxUploadTextWrapper>
                    {hintMessage && <FxUploadHintWrapper>{hintMessage}</FxUploadHintWrapper>}
                </FxDropzone>
            </SingleFileDropzoneToGivenUrlWrapper>
        );
    }
}

SingleFileDropzoneToGivenUrlComponent.propTypes = {
    listType: PropTypes.string,
    onlyView: PropTypes.bool,
    uploadToEndPoint: PropTypes.string.isRequired,
    pathToUrlInData: PropTypes.string,
    extraData: PropTypes.object,
    message: PropTypes.any,
    hintMessage: PropTypes.any,
    iconMessage: PropTypes.any,
};

function mapStateToProps(state) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        loggedInUser: get(state, 'settings.loggedInUser'),
        localTransporterAccount: get(state, 'local.localTransporterAccount', null),
    };
}

export default withTranslation()(connect(mapStateToProps, {})(SingleFileDropzoneToGivenUrlComponent));
