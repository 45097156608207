import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { customProgressBarConfig, FILE_UPLOAD_STATUS, getImageKitUploadEndpoint, getShowUploadListConfig, getUrlToUploadFileObject, getValidUploadFileTypes, iconRender, setFieldValueOnFile, UPLOAD_LIST_TYPE, validateFileBeforeUpload, } from 'utils/fileUploadutils';
import LightBoxUtils from 'utils/light_box_utils';
import { FxButton, FxDropzone, FxUploadDragIconWrapper, FxUploadHintWrapper, FxUploadTextWrapper } from 'sharedV2';
import { get, map, round, set } from 'lodash';
import axios from 'axios';
import { IMAGEKIT_PUBLIC_API_KEY } from 'constant';
import { getMomentTime, MINUTES_1 } from 'utils/date_utils';
import { connect } from 'react-redux';
import { fetchImagekitSHAV2, fetchImagekitSHAV2Public, getServerTime, setUploadLoading, } from 'actions/dashboard/utils_action';
import { v4 as uuidv4 } from 'uuid';
import { FxPlusIcon, FxUploadIcon } from 'sharedV2/FxIcons';
import Styled from 'styled-components';
const SingleFileDropzoneWrapper = Styled.div `
    .ant-progress-line{
      width:98%
    }
`;
const FxSingleFileDropzoneComponent = (props) => {
    const { t } = useTranslation();
    const { name, accept, message = t('dropzoneTextMsg'), iconMessage, hintMessage, onlyView, accesstoken, loggedInUser, showOnlyButton, buttonText = t('clickToUpload'), buttonType = 'default', listType = UPLOAD_LIST_TYPE.PICTURE, } = props;
    const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);
    const [fileList, setFileList] = useState(props.uploadedUrl ? [getUrlToUploadFileObject(props.uploadedUrl)] : []);
    const source = useRef(null);
    useEffect(() => {
        if (props.uploadedUrl !== get(fileList, '[0].url')) {
            setFileList(props.uploadedUrl ? [getUrlToUploadFileObject(props.uploadedUrl)] : []);
        }
    }, [props.uploadedUrl]);
    const setFieldOnFile = (pairs) => {
        setFileList((PrevFileList) => {
            const file = PrevFileList[0];
            if (file) {
                return [setFieldValueOnFile(file, pairs)];
            }
            return PrevFileList;
        });
    };
    const onDrop = async (acceptedFiles) => {
        const acceptedFile = acceptedFiles[0];
        if (!acceptedFile) {
            return;
        }
        if (source.current) {
            source.current.cancel('');
        }
        const fileValidationObj = await validateFileBeforeUpload(acceptedFile);
        if (get(fileValidationObj, 'error')) {
            const errorMsg = get(fileValidationObj, 'errorMsg');
            setFieldOnFile([
                ['status', FILE_UPLOAD_STATUS.ERROR],
                ['error', errorMsg],
            ]);
            props.fileErrorCallback && props.fileErrorCallback(errorMsg);
            return;
        }
        if (props.onDropCB) {
            props.onDropCB(acceptedFile);
            return;
        }
        const CancelToken = axios.CancelToken;
        source.current = CancelToken.source();
        const config = {
            onUploadProgress: (progressEvent) => {
                setFieldOnFile([['percent', round((progressEvent.loaded * 100) / progressEvent.total)]]);
            },
            cancelToken: source.current.token,
        };
        let timeStamp = Math.floor(getMomentTime().valueOf());
        try {
            const result = await props.getServerTime(accesstoken);
            timeStamp = get(result, 'payload.data.timestamp', timeStamp);
        }
        catch (e) {
            console.error('Error getting server time', e);
        }
        const token = uuidv4();
        const expire = round((timeStamp + MINUTES_1 * 30) / 1000, 0);
        if (!props.publicEpod) {
            props.fetchImagekitSHAV2(accesstoken, acceptedFile.name, token, expire).then((result) => {
                if (!result.error) {
                    const signature = get(result, 'payload.data.signature');
                    const expireAPI = get(result, 'payload.data.expire');
                    const data = new FormData();
                    data.append('file', acceptedFile);
                    data.append('fileName', acceptedFile.name);
                    data.append('publicKey', IMAGEKIT_PUBLIC_API_KEY);
                    data.append('signature', signature);
                    data.append('token', token);
                    data.append('expire', expireAPI);
                    data.append('useUniqueFilename', 'true');
                    const accountId = get(loggedInUser, 'accountId', '') || props.localTransporterAccount || props.accountId;
                    data.append('folder', `clients/${accountId}/${props.folderPath}`);
                    props.setUploadLoading(true);
                    axios
                        .post(getImageKitUploadEndpoint(props.localTransporterAccount), data, config)
                        .then((res) => {
                        setFieldOnFile([
                            ['status', FILE_UPLOAD_STATUS.DONE],
                            ['url', res.data.url],
                            ['thumbUrl', res.data.thumbnailUrl],
                        ]);
                        props.fileSuccessCallback(res.data.url);
                        props.setUploadLoading(false);
                    })
                        .catch((err) => {
                        props.setUploadLoading(false);
                        const errorMsg = get(err, 'response.data.message', '');
                        setFieldOnFile([
                            ['status', FILE_UPLOAD_STATUS.ERROR],
                            ['error', errorMsg],
                        ]);
                        props.fileErrorCallback && props.fileErrorCallback(errorMsg);
                    });
                }
            });
        }
        else {
            props.fetchImagekitSHAV2Public(acceptedFile.name, props.authToken || '', expire).then((result) => {
                if (!result.error) {
                    const signature = get(result, 'payload.data.signature');
                    const publicToken = get(result, 'payload.data.token');
                    const expireAPI = get(result, 'payload.data.expire');
                    const data = new FormData();
                    data.append('file', acceptedFile);
                    data.append('fileName', acceptedFile.name);
                    data.append('publicKey', IMAGEKIT_PUBLIC_API_KEY);
                    data.append('signature', signature);
                    data.append('token', publicToken);
                    data.append('expire', expireAPI);
                    data.append('useUniqueFilename', 'true');
                    data.append('folder', `clients${props.folderPath}`);
                    axios
                        .post('https://upload.imagekit.io/api/v1/files/upload', data, config)
                        .then((res) => {
                        setFieldOnFile([
                            ['status', FILE_UPLOAD_STATUS.DONE],
                            ['url', res.data.url],
                            ['thumbUrl', res.data.thumbnailUrl],
                        ]);
                        props.fileSuccessCallback(res.data.url);
                    })
                        .catch((err) => {
                        const errorMsg = get(err, 'response.data.message', '');
                        setFieldOnFile([
                            ['status', FILE_UPLOAD_STATUS.ERROR],
                            ['error', errorMsg],
                        ]);
                        props.fileErrorCallback && props.fileErrorCallback(errorMsg);
                    });
                }
            });
        }
    };
    const onFileRemove = () => {
        if (source.current) {
            source.current.cancel('');
        }
        setFileList([]);
        props.fileDeleteCallback && props.fileDeleteCallback();
    };
    const beforeUpload = (file) => {
        set(file, 'status', FILE_UPLOAD_STATUS.UPLOADING);
        const newFileList = [file];
        setFileList(newFileList);
        onDrop(newFileList);
        return false;
    };
    const onCloseRequest = () => {
        setIsDocViewerOpen(false);
    };
    const handleOnClick = () => {
        setIsDocViewerOpen(true);
    };
    const onPreview = (file) => {
        handleOnClick();
    };
    const uploadedImageLinks = map(fileList, (f) => f.url);
    const uploadProps = {
        onRemove: onFileRemove,
        name,
        beforeUpload,
        fileList,
        listType,
        multiple: false,
        disabled: onlyView,
        accept: getValidUploadFileTypes(accept),
        iconRender: iconRender(onPreview),
        isImageUrl: () => false,
        maxCount: 1,
        useDragger: !showOnlyButton,
        showUploadList: getShowUploadListConfig({ showOnlyButton, showRemoveIcon: !!props.fileDeleteCallback }),
        progress: customProgressBarConfig(),
    };
    const isUploading = get(fileList, '[0].status') === FILE_UPLOAD_STATUS.UPLOADING;
    const isPictureCard = listType === UPLOAD_LIST_TYPE.PICTURE_CARD;
    return (_jsxs(SingleFileDropzoneWrapper, { style: props.style, className: props.className, children: [isDocViewerOpen && _jsx(LightBoxUtils, { images: uploadedImageLinks, onCloseRequest: onCloseRequest }), _jsxs(FxDropzone, { ...uploadProps, children: [showOnlyButton && !isPictureCard && (_jsx(FxButton, { type: buttonType, disabled: isUploading, loading: isUploading, icon: _jsx(FxUploadIcon, {}), children: buttonText })), !showOnlyButton && !isPictureCard && (_jsxs("div", { children: [!!iconMessage && _jsx(FxUploadDragIconWrapper, { children: iconMessage }), _jsx(FxUploadTextWrapper, { children: message }), hintMessage && _jsx(FxUploadHintWrapper, { children: hintMessage })] })), isPictureCard && (_jsxs("div", { children: [_jsx(FxPlusIcon, {}), _jsx("div", { style: { marginTop: 8 }, children: t('upload') })] }))] })] }));
};
function mapStateToProps(state) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        loggedInUser: get(state, 'settings.loggedInUser'),
        localTransporterAccount: get(state, 'local.localTransporterAccount', null),
    };
}
export default connect(mapStateToProps, {
    fetchImagekitSHAV2,
    fetchImagekitSHAV2Public,
    getServerTime,
    setUploadLoading,
})(FxSingleFileDropzoneComponent);
